import { FancyButton } from "./Common/FancyButton";
import { colors } from "../theme/theme";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { SupremacyPNG } from "../assets";
import { ClipThing } from "./Common/ClipThing";
import { Image } from "@mui/icons-material";

export const HangarInteractWait = ({ disable, onClick }: { disable: boolean; onClick: () => void }) => {
   return (
     <Stack
       spacing="3rem"
       alignItems="center"
       justifyContent="center"
       sx={{
         position: "fixed",
         width: "100vw",
         height: "100%",
       }}
     >
       <ClipThing
         clipSize="10px"
         border={{
           borderColor: colors.neonBlue,
           borderThickness: ".25rem",
         }}
         sx={{ position: "relative", maxWidth: "70rem", my: "auto", mx: "2.6rem" }}
         backgroundColor={colors.darkerNavy}
         opacity={0.7}
       >
        <Stack alignItems="center" spacing=".8rem" padding="5.0rem">
          <img src={`${SupremacyPNG}`} width="75" style={{marginBottom: "20px"}}/>
          <FancyButton
               disabled={disable}
               clipThingsProps={{
                 clipSize: "5px",
                 backgroundColor: colors.green,
                 border: { isFancy: true, borderColor: colors.green },
                 sx: { position: "relative" },
               }}
               sx={{ px: "3rem", pt: ".4rem", pb: ".5rem", minWidth: "7rem", color: "#FFFFFF" }}
               onClick={onClick}
             >
               <Stack alignItems="center" justifyContent="center" direction="row">
                 <Typography
                   variant="body2"
                   sx={{
                     lineHeight: 1,
                     letterSpacing: ".6px",
                     fontWeight: "fontWeightBold",
                     whiteSpace: "nowrap",
                     textTransform: "none",
                     color: "#FFFFFF",
                   }}
                 >
                   Load Hangar
                 </Typography>
               </Stack>
             </FancyButton>
        </Stack>
       </ClipThing>
     </Stack>
   )
}