import Ability from "!@svgr/webpack!./svg/Ability.svg"
import Assets from "!@svgr/webpack!./svg/Assets.svg"
import BostonMechKillIcon from "!@svgr/webpack!./svg/BostonKillIcon.svg"
import Chest from "!@svgr/webpack!./svg/Chest.svg"
import Close from "!@svgr/webpack!./svg/Close.svg"
import Close2 from "!@svgr/webpack!./svg/Close2.svg"
import Copy from "!@svgr/webpack!./svg/Copy.svg"
import Death from "!@svgr/webpack!./svg/Death.svg"
import DropdownArrow from "!@svgr/webpack!./svg/DropdownArrow.svg"
import Emoji from "!@svgr/webpack!./svg/Emoji.svg"
import EmojiSelector from "!@svgr/webpack!./svg/EmojiSelector.svg"
import ExternalLink from "!@svgr/webpack!./svg/ExternalLink.svg"
import FastRepair from "!@svgr/webpack!./svg/FastRepair.svg"
import FontCase from "!@svgr/webpack!./svg/FontCase.svg"
import Global from "!@svgr/webpack!./svg/Global.svg"
import Graph from "!@svgr/webpack!./svg/Graph.svg"
import Hammer from "!@svgr/webpack!./svg/Hammer.svg"
import IntroAnimation from "!@svgr/webpack!./svg/IntroAnimation.svg"
import Unhide from "!@svgr/webpack!./svg/Unhide.svg"
import Logout from "!@svgr/webpack!./svg/Logout.svg"
import Meteor from "!@svgr/webpack!./svg/Meteor.svg"
import Armor from "!@svgr/webpack!./svg/Armor.svg"
import Energy from "!@svgr/webpack!./svg/Energy.svg"
import Mobility from "!@svgr/webpack!./svg/Mobility.svg"
import Health from "!@svgr/webpack!./svg/Health.svg"
import PowerCoreCapacity from "!@svgr/webpack!./svg/PowerCoreCapacity.svg"
import PowerCoreRegen from "!@svgr/webpack!./svg/PowerCoreRegen.svg"
import ShieldRegen from "!@svgr/webpack!./svg/ShieldRegen.svg"
import Shield from "!@svgr/webpack!./svg/Shield.svg"
import Speed from "!@svgr/webpack!./svg/Speed.svg"
import Ammo from "!@svgr/webpack!./svg/Ammo.svg"
import Damage1 from "!@svgr/webpack!./svg/Damage1.svg"
import DamageFalloff from "!@svgr/webpack!./svg/DamageFalloff.svg"
import DamageFalloffRate from "!@svgr/webpack!./svg/DamageFalloffRate.svg"
import Radius from "!@svgr/webpack!./svg/Radius.svg"
import RadiusDamageFalloffRate from "!@svgr/webpack!./svg/RadiusDamageFalloffRate.svg"
import RateOfFire from "!@svgr/webpack!./svg/RateOfFire.svg"
import ProjectileSpeed from "!@svgr/webpack!./svg/ProjectileSpeed.svg"
import Spread from "!@svgr/webpack!./svg/Spread.svg"
import NinjaSyndicateLogo from "!@svgr/webpack!./svg/NinjaSyndicateLogo.svg"
import OutroAnimation from "!@svgr/webpack!./svg/OutroAnimation.svg"
import Plus from "!@svgr/webpack!./svg/Plus.svg"
import PowerCore from "!@svgr/webpack!./svg/PowerCore.svg"
import Profile from "!@svgr/webpack!./svg/Profile.svg"
import Rank from "!@svgr/webpack!./svg/Rank.svg"
import RedMoutainMechKillIcon from "!@svgr/webpack!./svg/RedMountainKillIcon.svg"
import Refresh from "!@svgr/webpack!./svg/Refresh.svg"
import Save from "!@svgr/webpack!./svg/Save.svg"
import Scrolldown from "!@svgr/webpack!./svg/Scrolldown.svg"
import Send from "!@svgr/webpack!./svg/Send.svg"
import Shop from "!@svgr/webpack!./svg/Shop.svg"
import Skin from "!@svgr/webpack!./svg/Skin.svg"
import SupremacyLogo from "!@svgr/webpack!./svg/SupremacyLogo.svg"
import Utilities from "!@svgr/webpack!./svg/Utilities.svg"
import View from "!@svgr/webpack!./svg/View.svg"
import Wallet from "!@svgr/webpack!./svg/Wallet.svg"
import Weapons from "!@svgr/webpack!./svg/Weapons.svg"
import ZaibatsuMechKillIcon from "!@svgr/webpack!./svg/ZaibatsuKillIcon.svg"
import Angle from "!react-svg-loader!./svg/Angle.svg"
import Announcement from "!react-svg-loader!./svg/Announcement.svg"
import Applause from "!react-svg-loader!./svg/Applause.svg"
import Arrow from "!react-svg-loader!./svg/Arrow.svg"
import Back from "!react-svg-loader!./svg/Back.svg"
import Cancelled from "!react-svg-loader!./svg/Cancelled.svg"
import Chat from "!react-svg-loader!./svg/Chat.svg"
import ChatSplit from "!react-svg-loader!./svg/ChatSplit.svg"
import ChatTabbed from "!react-svg-loader!./svg/ChatTabbed.svg"
import Damage from "!react-svg-loader!./svg/Damage.svg"
import Disconnected from "!react-svg-loader!./svg/Disconnected.svg"
import Discord from "!react-svg-loader!./svg/Discord.svg"
import Drag from "!react-svg-loader!./svg/Drag.svg"
import Edit from "!react-svg-loader!./svg/Edit.svg"
import Emergency from "!react-svg-loader!./svg/Emergency.svg"
import Filter from "!react-svg-loader!./svg/Filter.svg"
import Fullscreen from "!react-svg-loader!./svg/Fullscreen.svg"
import GoldBars from "!react-svg-loader!./svg/GoldBars.svg"
import GridView from "!react-svg-loader!./svg/GridView.svg"
import Hide from "!react-svg-loader!./svg/Hide.svg"
import History from "!react-svg-loader!./svg/History.svg"
import HistoryClock from "!react-svg-loader!./svg/HistoryClock.svg"
import Hourglass from "!react-svg-loader!./svg/Hourglass.svg"
import Info from "!react-svg-loader!./svg/Info.svg"
import InfoCircular from "!react-svg-loader!./svg/InfoCircular.svg"
import Line from "!react-svg-loader!./svg/Line.svg"
import ListView from "!react-svg-loader!./svg/ListView.svg"
import Location from "!react-svg-loader!./svg/Location.svg"
import MapEnlarge from "!react-svg-loader!./svg/MapEnlarge.svg"
import MapSkull from "!react-svg-loader!./svg/MapSkull.svg"
import MapWarMachine from "!react-svg-loader!./svg/MapWarMachine.svg"
import Microchip from "!react-svg-loader!./svg/Microchip.svg"
import Music from "!react-svg-loader!./svg/Music.svg"
import MusicMute from "!react-svg-loader!./svg/MusicMute.svg"
import Next from "!react-svg-loader!./svg/Next.svg"
import Notification from "!react-svg-loader!./svg/Notification.svg"
import Play from "!react-svg-loader!./svg/Play.svg"
import QuestionMark from "!react-svg-loader!./svg/QuestionMark.svg"
import Radar from "!react-svg-loader!./svg/Radar.svg"
import Corporal from "!react-svg-loader!./svg/Ranks/Corporal.svg"
import General from "!react-svg-loader!./svg/Ranks/General.svg"
import NewRecruit from "!react-svg-loader!./svg/Ranks/NewRecruit.svg"
import Private from "!react-svg-loader!./svg/Ranks/Private.svg"
import ResizeX from "!react-svg-loader!./svg/ResizeX.svg"
import ResizeXY from "!react-svg-loader!./svg/ResizeXY.svg"
import ResizeY from "!react-svg-loader!./svg/ResizeY.svg"
import Robot from "!react-svg-loader!./svg/Robot.svg"
import Search from "!react-svg-loader!./svg/Search.svg"
import Settings from "!react-svg-loader!./svg/Settings.svg"
import Skull from "!react-svg-loader!./svg/Skull.svg"
import Skull2 from "!react-svg-loader!./svg/Skull2.svg"
import Stats from "!react-svg-loader!./svg/Stats.svg"
import Success from "!react-svg-loader!./svg/Success.svg"
import Support from "!react-svg-loader!./svg/Support.svg"
import SupToken from "!react-svg-loader!./svg/SupToken.svg"
import Target from "!react-svg-loader!./svg/Target.svg"
import Telegram from "!react-svg-loader!./svg/Telegram.svg"
import Twitter from "!react-svg-loader!./svg/Twitter.svg"
import Unknown from "!react-svg-loader!./svg/Unknown.svg"
import User from "!react-svg-loader!./svg/User.svg"
import Volume from "!react-svg-loader!./svg/Volume.svg"
import VolumeMute from "!react-svg-loader!./svg/VolumeMute.svg"
import WarnTriangle from "!react-svg-loader!./svg/WarnTriangle.svg"
import YouTube from "!react-svg-loader!./svg/YouTube.svg"
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp"
import AppsIcon from "@mui/icons-material/Apps"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowRightAltSharpIcon from "@mui/icons-material/ArrowRightAltSharp"
import CampaignSharpIcon from "@mui/icons-material/CampaignSharp"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import FlagSharpIcon from "@mui/icons-material/FlagSharp"
import HorizontalRuleSharpIcon from "@mui/icons-material/HorizontalRuleSharp"
import MapSharpIcon from "@mui/icons-material/MapSharp"
import WarningIcon from "@mui/icons-material/Warning"
import WorkspacesSharpIcon from "@mui/icons-material/WorkspacesSharp"
import { Box, BoxProps } from "@mui/system"
import { colors } from "../theme/theme"
import BostonCyberneticsLogo from "./images/BostonCyberneticsLogo.png"
import BottomMechWEBP from "./images/BottomMech.webp"
import ClaimsBg from "./images/ClaimsBg.jpg"
import Crosshair from "./images/Crosshair.png"
import EmptyWarMachinesPNG from "./images/EmptyWarMachines.png"
import FlamesPNG from "./images/Flames.png"
import Gabs from "./images/Gabs.png"
import GenericWarMachinePNG from "./images/GenericWarMachine.png"
import HangarBg from "./images/HangarBg.jpg"
import KeycardPNG from "./images/Keycard.png"
import LogoWEBP from "./images/Logo.webp"
import MaintenancePNG from "./images/Maintenance.png"
// Multiplier images (no spaces in file names, won't work!)
import MultiplierAdmiral from "./images/Multipliers/Admiral.png"
import MultiplierAFoolAndHisMoney from "./images/Multipliers/AFoolAndHisMoney.png"
import MultiplierAirMarshal from "./images/Multipliers/AirMarshal.png"
import MultiplierAirSupport from "./images/Multipliers/AirSupport.png"
import MultiplierContributor from "./images/Multipliers/Contributor.png"
import MultiplierDestroyerOfWorlds from "./images/Multipliers/DestroyerOfWorlds.png"
import MultiplierFieldMechanic from "./images/Multipliers/FieldMechanic.png"
import MultiplierGeneric from "./images/Multipliers/Generic.png"
import MultiplierGreaseMonkey from "./images/Multipliers/GreaseMonkey.png"
import MultiplierJunkE from "./images/Multipliers/JunkE.png"
import MultiplierMechCommander from "./images/Multipliers/MechCommander.png"
import MultiplierMechHead from "./images/Multipliers/MechHead.png"
import MultiplierNowIAmBecomeDeath from "./images/Multipliers/NowIAmBecomeDeath.png"
import MultiplierSniper from "./images/Multipliers/Sniper.png"
import MultiplierWonBattle from "./images/Multipliers/WonBattle.png"
import MultiplierWonLastThreeBattles from "./images/Multipliers/WonLastThreeBattles.png"
import PlayerAbilityPNG from "./images/PlayerAbility.png"
import RedMountainLogo from "./images/RedMountainLogo.png"
import SafePNG from "./images/Safe.png"
import SupBackground from "./images/SupBackground.webp"
import SupremacyPNG from "./images/Supremacy.png"
import RainingSupsPNG from "./images/supsRain.png"
import TrailerThumbPNG from "./images/TrailerThumb.png"
import WarMachineIconPNG from "./images/WarMachineIcon.png"
import ZaibatsuLogo from "./images/ZaibatsuLogo.png"
import { Cake } from "@mui/icons-material"

export {
    MultiplierAdmiral,
    MultiplierAFoolAndHisMoney,
    MultiplierAirSupport,
    MultiplierContributor,
    MultiplierDestroyerOfWorlds,
    MultiplierFieldMechanic,
    MultiplierGreaseMonkey,
    MultiplierMechCommander,
    MultiplierNowIAmBecomeDeath,
    MultiplierAirMarshal,
    MultiplierJunkE,
    MultiplierMechHead,
    MultiplierSniper,
    MultiplierWonBattle,
    MultiplierGeneric,
    MultiplierWonLastThreeBattles,
}
// End multiplier images
export {
    KeycardPNG,
    HangarBg,
    ClaimsBg,
    SupremacyPNG,
    GenericWarMachinePNG,
    FlamesPNG,
    MaintenancePNG,
    TrailerThumbPNG,
    LogoWEBP,
    BottomMechWEBP,
    BostonCyberneticsLogo,
    RedMountainLogo,
    ZaibatsuLogo,
    SupBackground,
    Crosshair,
    Gabs,
    EmptyWarMachinesPNG,
    SafePNG,
    RainingSupsPNG,
    WarMachineIconPNG,
    PlayerAbilityPNG,
}

export interface SvgWrapperProps extends BoxProps {
    size?: string
    fill?: string
    height?: string
    width?: string
    stroke?: string
    strokeWidth?: string
}

export const SvgWrapper: React.FC<SvgWrapperProps> = ({ fill, stroke, strokeWidth, sx, size, width, height, ...props }: SvgWrapperProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pb: 0.3,
                "& > svg": {
                    width: width || size || "2rem",
                    height: height || size || "2rem",
                    fill: fill || colors.text,
                    stroke: stroke || "unset",
                    strokeWidth: strokeWidth || "1",
                },
                ...sx,
            }}
            {...props}
        />
    )
}

export const SvgSupToken: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <SupToken />
    </SvgWrapper>
)

export const SvgSyndicateFlag: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <FlagSharpIcon />
    </SvgWrapper>
)

export const SvgSkull: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Skull />
    </SvgWrapper>
)

export const SvgMapSkull: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <MapSkull />
    </SvgWrapper>
)

export const SvgMapWarMachine: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <MapWarMachine />
    </SvgWrapper>
)

export const SvgMapSharpIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <MapSharpIcon />
    </SvgWrapper>
)

export const SvgActivities: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <CampaignSharpIcon />
    </SvgWrapper>
)

export const SvgActions: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <WorkspacesSharpIcon />
    </SvgWrapper>
)

export const SvgDrag: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Drag />
    </SvgWrapper>
)

export const SvgCooldown: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <AccessTimeSharpIcon />
    </SvgWrapper>
)

export const SvgPriceUpArrow: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ArrowDropUpIcon />
    </SvgWrapper>
)

export const SvgPriceDownArrow: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ArrowDropDownIcon />
    </SvgWrapper>
)

export const SvgApplause: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Applause />
    </SvgWrapper>
)

export const SvgSettings: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Settings />
    </SvgWrapper>
)

export const SvgFullscreen: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Fullscreen />
    </SvgWrapper>
)

export const SvgVolume: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Volume />
    </SvgWrapper>
)

export const SvgVolumeMute: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <VolumeMute />
    </SvgWrapper>
)

export const SvgUser: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <User />
    </SvgWrapper>
)

export const SvgBattleAbilityIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Unknown />
    </SvgWrapper>
)

export const SvgChatIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Chat />
    </SvgWrapper>
)

export const SvgInfoIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Info />
    </SvgWrapper>
)

export const SvgDamageIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Damage />
    </SvgWrapper>
)

export const SvgDamageCross: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <CloseSharpIcon />
    </SvgWrapper>
)

export const SvgMapEnlarge: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <MapEnlarge />
    </SvgWrapper>
)

export const SvgResizeX: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ResizeX />
    </SvgWrapper>
)

export const SvgResizeY: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ResizeY />
    </SvgWrapper>
)

export const SvgResizeXY: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ResizeXY />
    </SvgWrapper>
)

export const SvgHide: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Hide />
    </SvgWrapper>
)

export const SvgRadar: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Radar />
    </SvgWrapper>
)

export const SvgGoldBars: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <GoldBars />
    </SvgWrapper>
)

export const SvgRobot: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Robot />
    </SvgWrapper>
)

export const SvgHistory: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <History />
    </SvgWrapper>
)

export const SvgPlay: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Play />
    </SvgWrapper>
)

export const SvgDiscord: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Discord />
    </SvgWrapper>
)

export const SvgTwitter: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Twitter />
    </SvgWrapper>
)

export const SvgYouTube: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <YouTube />
    </SvgWrapper>
)

export const SvgTelegram: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Telegram />
    </SvgWrapper>
)

export const SvgSkull2: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Skull2 />
    </SvgWrapper>
)

export const SvgDisconnected: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Disconnected />
    </SvgWrapper>
)

export const SvgLocation: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Location />
    </SvgWrapper>
)

export const SvgHourglass: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Hourglass />
    </SvgWrapper>
)

export const SvgEmergency: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Emergency />
    </SvgWrapper>
)

export const SvgCancelled: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Cancelled />
    </SvgWrapper>
)

export const SvgNinjaSyndicateLogo: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <NinjaSyndicateLogo />
    </SvgWrapper>
)

export const SvgSupremacyLogo: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <SupremacyLogo />
    </SvgWrapper>
)

export const SvgWallet: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Wallet />
    </SvgWrapper>
)

export const SvgPlus: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Plus />
    </SvgWrapper>
)

export const SvgRank: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Rank />
    </SvgWrapper>
)

export const SvgHorizontalRuleSharpIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <HorizontalRuleSharpIcon />
    </SvgWrapper>
)

export const SvgArrowRightAltSharpIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ArrowRightAltSharpIcon />
    </SvgWrapper>
)

export const SvgChat: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Chat />
    </SvgWrapper>
)

export const SvgSend: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Send />
    </SvgWrapper>
)

export const SvgGlobal: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Global />
    </SvgWrapper>
)

export const SvgDropdownArrow: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <DropdownArrow />
    </SvgWrapper>
)

export const SvgAbility: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Ability />
    </SvgWrapper>
)

export const SvgView: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <View />
    </SvgWrapper>
)

export const SvgDeath: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Death />
    </SvgWrapper>
)

export const SvgAssets: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Assets />
    </SvgWrapper>
)

export const SvgShop: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Shop />
    </SvgWrapper>
)

export const SvgProfile: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Profile />
    </SvgWrapper>
)

export const SvgLogout: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Logout />
    </SvgWrapper>
)

export const SvgAppsIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <AppsIcon />
    </SvgWrapper>
)

export const SvgExternalLink: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ExternalLink />
    </SvgWrapper>
)

export const SvgRefresh: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Refresh />
    </SvgWrapper>
)

export const SvgEmoji: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Emoji />
    </SvgWrapper>
)

export const SvgInfoCircular: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <InfoCircular />
    </SvgWrapper>
)

export const SvgFastRepair: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <FastRepair />
    </SvgWrapper>
)

export const SvgScrolldown: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Scrolldown />
    </SvgWrapper>
)

export const SvgClose: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Close />
    </SvgWrapper>
)

export const SvgContentCopyIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Copy />
    </SvgWrapper>
)

export const SvgQuestionMark: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <QuestionMark />
    </SvgWrapper>
)

export const SvgChatSplit: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ChatSplit />
    </SvgWrapper>
)

export const SvgChatTabbed: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ChatTabbed />
    </SvgWrapper>
)

export const SvgFontCase: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <FontCase />
    </SvgWrapper>
)

export const SvgZaibatsuKillIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ZaibatsuMechKillIcon />
    </SvgWrapper>
)

export const SvgBostonKillIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <BostonMechKillIcon />
    </SvgWrapper>
)

export const SvgRedMoutainKillIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <RedMoutainMechKillIcon />
    </SvgWrapper>
)

export const SvgWarnTriangle: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <WarnTriangle />
    </SvgWrapper>
)

export const SvgSuccess: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Success />
    </SvgWrapper>
)

export const SvgClose2: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Close2 />
    </SvgWrapper>
)

export const SvgWarningIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <WarningIcon />
    </SvgWrapper>
)

export const SvgEmojiSelector: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <EmojiSelector />
    </SvgWrapper>
)

export const SvgAnnouncement: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Announcement />
    </SvgWrapper>
)

export const SvgGeneral: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <General />
    </SvgWrapper>
)

export const SvgCorporal: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Corporal />
    </SvgWrapper>
)

export const SvgPrivate: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Private />
    </SvgWrapper>
)

export const SvgNewRecruit: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <NewRecruit />
    </SvgWrapper>
)

export const SvgHistoryClock: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <HistoryClock />
    </SvgWrapper>
)

export const SvgBack: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Back />
    </SvgWrapper>
)

export const SvgEdit: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Edit />
    </SvgWrapper>
)

export const SvgSave: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Save />
    </SvgWrapper>
)

export const SvgNext: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Next />
    </SvgWrapper>
)

export const SvgListView: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ListView />
    </SvgWrapper>
)

export const SvgGridView: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <GridView />
    </SvgWrapper>
)

export const SvgGraph: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Graph />
    </SvgWrapper>
)

export const SvgChest: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Chest />
    </SvgWrapper>
)

export const SvgExpandLessIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ExpandLessIcon />
    </SvgWrapper>
)

export const SvgExpandMoreIcon: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ExpandMoreIcon />
    </SvgWrapper>
)

export const SvgMusic: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Music />
    </SvgWrapper>
)

export const SvgMusicMute: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <MusicMute />
    </SvgWrapper>
)

export const SvgTarget: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Target />
    </SvgWrapper>
)

export const SvgMicrochip: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Microchip />
    </SvgWrapper>
)

export const SvgSupport: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Support />
    </SvgWrapper>
)

export const SvgLine: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Line />
    </SvgWrapper>
)

export const SvgFilter: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Filter />
    </SvgWrapper>
)

export const SvgPowerCore: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <PowerCore />
    </SvgWrapper>
)

export const SvgIntroAnimation: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <IntroAnimation />
    </SvgWrapper>
)

export const SvgOutroAnimation: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <OutroAnimation />
    </SvgWrapper>
)

export const SvgSkin: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Skin />
    </SvgWrapper>
)

export const SvgUtilities: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Utilities />
    </SvgWrapper>
)

export const SvgWeapons: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Weapons />
    </SvgWrapper>
)

export const SvgHammer: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Hammer />
    </SvgWrapper>
)

export const SvgSearch: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Search />
    </SvgWrapper>
)

export const SvgAngle: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Angle />
    </SvgWrapper>
)

export const SvgStats: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Stats />
    </SvgWrapper>
)

export const SvgNotification: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Notification />
    </SvgWrapper>
)

export const SvgMeteor: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Meteor />
    </SvgWrapper>
)

export const SvgArrow: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Arrow />
    </SvgWrapper>
)

export const SvgAmmo: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Ammo />
    </SvgWrapper>
)

export const SvgDamage1: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Damage1 />
    </SvgWrapper>
)

export const SvgDamageFalloff: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <DamageFalloff />
    </SvgWrapper>
)

export const SvgDamageFalloffRate: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <DamageFalloffRate />
    </SvgWrapper>
)

export const SvgRadius: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Radius />
    </SvgWrapper>
)

export const SvgRadiusDamageFalloffRate: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <RadiusDamageFalloffRate />
    </SvgWrapper>
)

export const SvgRateOfFire: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <RateOfFire />
    </SvgWrapper>
)

export const SvgProjectileSpeed: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ProjectileSpeed />
    </SvgWrapper>
)

export const SvgSpread: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Spread />
    </SvgWrapper>
)

export const SvgArmor: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Armor />
    </SvgWrapper>
)

export const SvgEnergy: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Energy />
    </SvgWrapper>
)

export const SvgMobility: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Mobility />
    </SvgWrapper>
)

export const SvgShield: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Shield />
    </SvgWrapper>
)

export const SvgSpeed: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Speed />
    </SvgWrapper>
)

export const SvgCake: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Cake />
    </SvgWrapper>
)

export const SvgHealth: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Health />
    </SvgWrapper>
)

export const SvgPowerCoreCapacity: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <PowerCoreCapacity />
    </SvgWrapper>
)

export const SvgPowerCoreRegen: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <PowerCoreRegen />
    </SvgWrapper>
)

export const SvgShieldRegen: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <ShieldRegen />
    </SvgWrapper>
)

export const SvgUnhide: React.VoidFunctionComponent<SvgWrapperProps> = (props) => (
    <SvgWrapper {...props}>
        <Unhide />
    </SvgWrapper>
)
