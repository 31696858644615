import { Box, LinearProgress, Stack, Typography } from "@mui/material"
import { Buffer } from "buffer"
import { useEffect } from "react"
import ReactDOM from "react-dom"
import { Action, ClientContextProvider, createClient } from "react-fetching-library"
import { BrowserRouter, Route } from "react-router-dom"
import { SupremacyPNG } from "./assets"
import { GlobalSnackbar } from "./components/Common/GlobalSnackbar"
import { GAME_SERVER_HOSTNAME, UNDER_MAINTENANCE } from "./constants"
import { AuthProvider, SnackBarProvider, SupremacyProvider, useAuth, useSupremacy } from "./containers"
import { FingerprintProvider } from "./containers/fingerprint"
import { ThemeProvider } from "./containers/theme"
import { ws } from "./containers/ws"
import { useToggle } from "./hooks"
import { AuthPage } from "./pages/AuthPage"
import { EnlistPage } from "./pages/EnlistPage"
import { HangarPage } from "./pages/HangarPage"
import { LoginRedirection } from "./pages/LoginRedirect"
import { MaintenancePage } from "./pages/MaintenancePage"
import { colors, fonts } from "./theme/theme"

window.Buffer = Buffer

ws.Initialise({ defaultHost: GAME_SERVER_HOSTNAME })

const prefixURL = (prefix: string) => () => async (action: Action) => {
    return {
        ...action,
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("auth-token") || "",
            ...action.headers,
        },
        endpoint: action.endpoint.startsWith("http") ? action.endpoint : `${prefix}${action.endpoint}`,
    }
}

const client = createClient({
    //None of the options is required
    requestInterceptors: [prefixURL(`${window.location.protocol}//${GAME_SERVER_HOSTNAME}/api`)],
    responseInterceptors: [],
})

const App = () => {
    return (
        <ThemeProvider>
            <FingerprintProvider>
                <SnackBarProvider>
                    <ClientContextProvider client={client}>
                        <AuthProvider>
                            <SupremacyProvider>
                                <BrowserRouter>
                                    <Route path="/login-redirect" exact component={LoginRedirection} />
                                    <Route path="/" component={AppInner} />
                                </BrowserRouter>
                            </SupremacyProvider>
                        </AuthProvider>
                    </ClientContextProvider>
                </SnackBarProvider>
            </FingerprintProvider>
        </ThemeProvider>
    )
}

const AppInner = () => {
    const { serverConnectedBefore, isServerUp } = useSupremacy()
    const { userID, factionID } = useAuth()
    const [showLoading, toggleShowLoading] = useToggle(true)

    // Makes the loading screen to show for AT LEAST 1 second
    useEffect(() => {
        const timeout = setTimeout(() => {
            toggleShowLoading(false)
        }, 2000)

        return () => clearTimeout(timeout)
    }, [toggleShowLoading])

    if (!serverConnectedBefore || showLoading) {
        return (
            <Stack
                spacing="3rem"
                alignItems="center"
                justifyContent="center"
                sx={{
                    position: "fixed",
                    width: "100vw",
                    height: "100%",
                    backgroundColor: (theme) => theme.factionTheme.background,
                }}
            >
                <Box
                    sx={{
                        width: "9rem",
                        height: "9rem",
                        background: `url(${SupremacyPNG})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                    }}
                />

                <Stack alignItems="center" spacing=".8rem">
                    <Typography variant="body2" sx={{ textAlign: "center", fontFamily: fonts.nostromoBlack }}>
                        CONNECTING...
                    </Typography>
                    <LinearProgress
                        sx={{
                            width: "13rem",
                            height: "9px",
                            backgroundColor: `${colors.gold}15`,
                            ".MuiLinearProgress-bar": { backgroundColor: colors.gold },
                        }}
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <>
            <Stack
                sx={{
                    position: "fixed",
                    width: "100vw",
                    height: "100%",
                    backgroundColor: (theme) => theme.factionTheme.background,
                }}
            >
                {isServerUp && !UNDER_MAINTENANCE ? (
                    <>
                        {!userID && <AuthPage />}
                        {userID && !factionID && <EnlistPage />}
                        {userID && factionID && <HangarPage />}
                    </>
                ) : (
                    <MaintenancePage />
                )}
            </Stack>

            <GlobalSnackbar />
        </>
    )
}

ReactDOM.render(<App />, document.getElementById("root"))
