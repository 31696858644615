const USE_PROD = false
const USE_STAGING = process.env.REACT_APP_USE_STAGING === "true"

// Envar stuff
export const STAGING_OR_DEV_ONLY = process.env.REACT_APP_ENVIRONMENT !== "production"
export const DEV_ONLY = process.env.REACT_APP_ENVIRONMENT !== "production" && process.env.REACT_APP_ENVIRONMENT !== "staging"
const VERSION = process.env.REACT_APP_COMMIT_REF || "development"
const TOKEN_SALE_PAGE = process.env.REACT_APP_TOKEN_SALE_PAGE || "https://passport.xsyn.io/external/buy"
const SUPREMACY_PAGE = process.env.REACT_APP_SUPREMACY_PAGE || "https://supremacy.game/"
const VIDEO_SERVER_WEBSOCKET = process.env.REACT_APP_PASSPORT_SERVER_HOST || "wss://staging-watch.supremacy.game:5443/WebRTCAppEE/websocket"
const VIDEO_SERVER_STREAM_ID = process.env.REACT_APP_PASSPORT_SERVER_HOST || "524280586954581049507513"
let GAME_SERVER_HOSTNAME = process.env.REACT_APP_GAME_SERVER_HOSTNAME || "api.supremacygame.io"
let PASSPORT_WEB = process.env.REACT_APP_PASSPORT_WEB || "https://passport.xsyndev.io/"
let PASSPORT_SERVER_HOST = process.env.REACT_APP_PASSPORT_SERVER_HOST || "passport.supremacygame.io"
let PASSPORT_SERVER_HOST_IMAGES = process.env.REACT_APP_SERVER_HOST_IMAGES || "https://api.supremacygame.io"
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "develop"
const HANGAR_VERSION = process.env.REACT_APP_HANGAR_VERSION || "v1.0.0"

if (USE_PROD) {
    GAME_SERVER_HOSTNAME = process.env.REACT_APP_GAME_SERVER_HOSTNAME || "api.supremacy.game"
    PASSPORT_WEB = process.env.REACT_APP_PASSPORT_WEB || "https://passport.xsyn.io/"
    PASSPORT_SERVER_HOST = process.env.REACT_APP_PASSPORT_SERVER_HOST || "api.xsyn.io"
    PASSPORT_SERVER_HOST_IMAGES = process.env.REACT_APP_SERVER_HOST_IMAGES || "https://api.xsyn.io"
}

if (USE_STAGING) {
    GAME_SERVER_HOSTNAME = process.env.REACT_APP_GAME_SERVER_HOSTNAME || "api.supremacygame.dev"
    PASSPORT_WEB = process.env.REACT_APP_PASSPORT_WEB || "https://staging.xsyn.dev/"
    PASSPORT_SERVER_HOST = process.env.REACT_APP_PASSPORT_SERVER_HOST || "passport.supremacygame.dev"
    PASSPORT_SERVER_HOST_IMAGES = process.env.REACT_APP_SERVER_HOST_IMAGES || "https://staging.xsyn.dev/"
}

export {
    VERSION,
    GAME_SERVER_HOSTNAME,
    TOKEN_SALE_PAGE,
    SUPREMACY_PAGE,
    PASSPORT_WEB,
    PASSPORT_SERVER_HOST,
    PASSPORT_SERVER_HOST_IMAGES,
    VIDEO_SERVER_WEBSOCKET,
    VIDEO_SERVER_STREAM_ID,
    ENVIRONMENT,
HANGAR_VERSION
}

// UI related stuff
export const GAME_BAR_HEIGHT = 5.9 // rem
export const RIGHT_DRAWER_WIDTH = 38 // rem
export const CONTROLS_HEIGHT = 3.0 // rem
export const MINI_MAP_DEFAULT_SIZE = 240 //px
export const BOTTOM_NAV_HEIGHT = 85 // rem

export const DRAWER_TRANSITION_DURATION = 250
export const MESSAGES_BUFFER_SIZE = 500
export const MAX_CHAT_MESSAGE_LENGTH = 280
export const NOTIFICATION_TIME = 30000
export const NOTIFICATION_LINGER = 400
export const MAX_BAN_PROPOSAL_REASON_LENGTH = 150

export enum FactionIDs {
    RM = "98bf7bb3-1a7c-4f21-8843-458d62884060",
    ZHI = "880db344-e405-428d-84e5-6ebebab1fe6d",
    BC = "7c6dde21-b067-46cf-9e56-155c88a520e2",
}

// Other stuff
export const NullUUID = "00000000-0000-0000-0000-000000000000"
export const TRAILER_VIDEO =
    "https://player.vimeo.com/progressive_redirect/playback/681913587/rendition/1080p?loc=external&signature=6d5bf3570be8bd5e9e57a6a786964a99d067957fbcf9e3a40b6914c085c9b3e9#t=10"

// Maintenance (ENVAR). The local stroage is a hack to let the team members in
export const UNDER_MAINTENANCE = process.env.REACT_APP_MAINTENANCE_PAGE === "true" && !localStorage.getItem("NinjaSecrets@!")

// Unity asset paths
export const WEBGL_BASE_URL = "https://afiles.ninja-cdn.com/supremacy-hangar/build/";
export const DEVELOPMENT_BUILD_NUM = 18;