import { useEffect, useMemo, useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { Connect, DefaultHost, Socket, Subscribe, Subscriber, Urify } from "./context"

interface WSProps {
    URI: string
    key?: string
    batchURI?: string
    host?: string
    ready?: boolean
    subscriber?: Subscriber
    isCommander?: boolean
}

export const useWS = ({ URI, key, host, batchURI, ready = true, subscriber, isCommander }: WSProps) => {
    const wsURI = useMemo(() => Urify({ URI, host: host || DefaultHost(), batchURI }), [URI, host, batchURI])
    const isBrowser = typeof window !== "undefined"
    const socket = Socket(wsURI)
    const ident = useRef<string>(uuidv4())
    const [socketReady, setSocketReady] = useState<boolean>(false)
    const mounted = useRef<boolean>(true)
    const cleanupConn = useRef<() => void>(() => {
        return
    })

    const [socketState, setSocketState] = useState<number>(isBrowser ? WebSocket.CONNECTING : 0)
    useEffect(() => {
        if (!ready) return
        if (subscriber) {
            Subscribe({
                uri: wsURI,
                key: key,
                sub: subscriber,
                observer: setSocketState,
                isCommander,
            }).then((cleanup) => {
                if (!mounted.current) {
                    cleanup()
                } else {
                    cleanupConn.current = cleanup
                    setSocketReady(true)
                }
            })
        } else {
            Connect({
                uri: wsURI,
                ident: ident.current,
                observer: setSocketState,
                isCommander,
            }).then((cleanup) => {
                if (!mounted.current) {
                    cleanup()
                } else {
                    cleanupConn.current = cleanup
                    setSocketReady(true)
                }
            })
        }
        return () => {
            mounted.current = false
            cleanupConn.current()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [URI, host, ready, wsURI])

    return {
        state: !isBrowser ? 0 : !socketReady ? WebSocket.CONNECTING : socketState,
        key: wsURI,
        socket,
    }
}
