import { Box } from "@mui/material";
import {useCallback, useEffect, useRef, useState} from "react";
// @ts-ignore
import {useUnityContext} from "react-unity-webgl";
import {HangarBg, SupremacyPNG} from "../assets";
import { DEVELOPMENT_BUILD_NUM, ENVIRONMENT, PASSPORT_SERVER_HOST, WEBGL_BASE_URL } from "../constants";
import {useAuth} from "../containers";
import {useGameServerCommandsFaction, useGameServerCommandsUser} from "../hooks/useGameServer";
import {GameServerKeys} from "../keys";
import {HangarSilo, SiloType} from "../types";
import {useQuery} from "react-fetching-library";
import { HangarInteractWait } from "../components/HangarInteractWait";
import { HangarLoading } from "../components/HangarLoading";
import { HangarUnity } from "../components/HangarUnity";

enum PageState {
    Startup,
    Loading,
    Running,
}

export const HangarPage = () => {
    const {userID, factionID} = useAuth()
    const {send} = useGameServerCommandsUser("/user_commander")
    const [hangarSilo, setHangarSilo] = useState<HangarSilo>()

    useEffect(() => {
        if (!userID && !send && !factionID) return
            ;
        (async () => {
            try {
                const resp = await send<HangarSilo>(GameServerKeys.GetHangarItems)
                if (!resp) {
                    const emptySilo: HangarSilo = {
                        faction: factionID,
                        silos: []
                    }

                    setHangarSilo(emptySilo)
                    return
                }
                setHangarSilo(resp)
            } catch (e) {
                console.error(e)
            }
        })()
    }, [send, factionID, userID])

    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            backgroundImage: `url(${HangarBg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            boxShadow: `inset 0 0 50px 60px #00000090`,
        }}>
            <Hangar siloItems={hangarSilo}/>
        </Box>
    )
}

const interactionTime = 1000

const Hangar = ({siloItems}: { siloItems?: HangarSilo }) => {
    const [lastInteract, setLastInteract] = useState<number>(0)
    const [pageState, setPageState] = useState<PageState>(PageState.Startup)
    const {send} = useGameServerCommandsFaction("/faction_commander")
    const { query: gameserverLogout } = useQuery(
        {
            method: "GET",
            endpoint: `/auth/logout`,
            responseType: "json",
            credentials: "include",
        },
        false,
    )

    const { query: passportLogout } = useQuery(
        {
            method: "GET",
            endpoint: `${window.location.protocol}//${PASSPORT_SERVER_HOST}/api/auth/logout`,
            responseType: "json",
            credentials: "include",
        },
        false,
    )

    const [gameReady, setGameReady] = useState<boolean>(false)
    const sent = useRef(false)

    let baseUrl = WEBGL_BASE_URL;

    if (ENVIRONMENT === "develop") {
        baseUrl += `build-${DEVELOPMENT_BUILD_NUM}/`;
    } else {
        baseUrl += ENVIRONMENT + "/";
    }

    const {unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded} = useUnityContext({
        loaderUrl: `${baseUrl}WebGL.loader.js`,
        dataUrl: `${baseUrl}/WebGL.data.br`,
        frameworkUrl: `${baseUrl}/WebGL.framework.js.br`,
        codeUrl: `${baseUrl}/WebGL.wasm.br`,
        streamingAssetsUrl: `${baseUrl}/StreamingAssets`
    })

    const handleCrateOpeningRequest = useCallback(async (ownership_id: string) => {
        console.log("NEW ", ownership_id)
        try {
            const resp = await send<SiloType>(GameServerKeys.CrateOpen, {
                id: ownership_id,
                is_hangar: true
            })
            if (!resp) return
            sendMessage("ProjectContext(Clone)", "GetCrateContentsFromPage", JSON.stringify(resp))
        } catch (e) {
            console.error(e)
        }
    }, [send, sendMessage])

    useEffect(() => {
        if (pageState != PageState.Loading) return;
        if (!isLoaded || !gameReady || !siloItems || sent.current) return
        setPageState(PageState.Running);
        console.log("SENT")
        sendMessage("ProjectContext(Clone)", "GetPlayerInventoryFromPage", JSON.stringify(siloItems))
        sent.current = true

    }, [pageState, sendMessage, siloItems, gameReady])

    useEffect(() => {
        const cleanup = () => removeEventListener("SiloReady", () => setGameReady(true))
        cleanup()
        addEventListener("SiloReady", () => setGameReady(true))
        return cleanup;
    }, [addEventListener, removeEventListener])

    useEffect(() => {
        if (!gameReady) return
        const cleanup = () => removeEventListener("RequestCrateContent", handleCrateOpeningRequest)
        cleanup()
        addEventListener("RequestCrateContent", handleCrateOpeningRequest)
        return cleanup;
    }, [addEventListener, removeEventListener, gameReady, handleCrateOpeningRequest])

    const pageParts = [];
    let unityVisible = false;

    const handleInteractClick = () => {
        setLastInteract(Date.now());
        if (pageState == PageState.Startup) setPageState(PageState.Loading);
    }

    switch (pageState) {
        case PageState.Loading:
            pageParts.push(<HangarLoading key="Loading"/>)
            break
        case PageState.Startup:
            pageParts.push(<HangarInteractWait key="wait" disable={false} onClick={handleInteractClick}/>)
            break
        case PageState.Running:
            unityVisible = true;
            break
    }

    pageParts.push(<HangarUnity key="unity" visible={unityVisible} provider={unityProvider}/>)

    return <div style={{height: "100%", width: "100%"}}>{pageParts}</div>;
}