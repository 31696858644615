import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { SupremacyPNG } from "../assets";
import { colors, fonts } from "../theme/theme";
import { ClipThing } from "./Common/ClipThing";

export const HangarLoading = () => {
  return (
    <Stack
      spacing="3rem"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "fixed",
        width: "100vw",
        height: "100%",
      }}
    >
      <ClipThing
        clipSize="10px"
        border={{
          borderColor: colors.neonBlue,
          borderThickness: ".3rem",
        }}
        sx={{ position: "relative", maxWidth: "70rem", my: "auto", mx: "2.6rem" }}
        backgroundColor={colors.darkerNavy}
        opacity={0.7}
      >
      <Stack alignItems="center" spacing=".8rem" padding="5.0rem">
        <img src={`${SupremacyPNG}`} width="75" style={{marginBottom: "20px"}}/>
        <Typography variant="body2" sx={{textAlign: "center", fontFamily: fonts.nostromoBlack}}>
          LOADING ASSETS...
        </Typography>
        <LinearProgress
          sx={{
            width: "13rem",
            height: "9px",
            backgroundColor: `${colors.gold}15`,
            ".MuiLinearProgress-bar": {backgroundColor: colors.gold},
          }}
        />
      </Stack>
      </ClipThing>
    </Stack>
  )
}

