import { Action } from "react-fetching-library"
import { GAME_SERVER_HOSTNAME } from "../constants"
import { Fingerprint } from "../containers/fingerprint"
import { Faction, User, UserFromPassport } from "../types"

export const PassportLoginCheck = (formValues: { issue_token: string; fingerprint?: Fingerprint }): Action<UserFromPassport> => {
    const { issue_token, fingerprint } = formValues
    return {
        method: "POST",
        endpoint: `${window.location.protocol}//${GAME_SERVER_HOSTNAME}/api/auth/xsyn`,
        credentials: "include",
        responseType: "json",
        body: { issue_token, fingerprint },
    }
}

export const GameServerLoginCheck = (fingerprint?: Fingerprint): Action<User> => {
    return {
        method: "POST",
        endpoint: `${window.location.protocol}//${GAME_SERVER_HOSTNAME}/api/auth/check`,
        credentials: "include",
        responseType: "json",
        body: { fingerprint },
    }
}

export const GetFactionsAll = (): Action<Faction[]> => {
    return {
        method: "GET",
        endpoint: "/faction/all",
        credentials: "include",
        responseType: "json",
    }
}

// headers: {
// 	"X-Authorization": xxxxx
// },
