import { createContext, Dispatch, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { useFingerprint, useSupremacy } from "."
import { PASSPORT_WEB } from "../constants"
import { GameServerLoginCheck, PassportLoginCheck } from "../fetching"
import { shadeColor } from "../helpers"
import { useGameServerSubscriptionUser } from "../hooks/useGameServer"
import { GameServerKeys } from "../keys"
import { colors } from "../theme/theme"
import { Faction, FeatureName, User, UserFromPassport, UserRank, UserStat } from "../types"
import { useTheme } from "./theme"

export const FallbackUser: User = {
    id: "",
    faction_id: "",
    username: "UNKNOWN",
    gid: 0,
    rank: "NEW_RECRUIT",
    features: [],
}

export const FallbackFaction: Faction = {
    id: "",
    label: "",
    logo_url: "",
    background_url: "",
    wallpaper_url: "",
    primary_color: colors.neonBlue,
    secondary_color: "#000000",
    background_color: shadeColor(colors.neonBlue, -95),
    description: "",
}

export interface AuthState {
    isLoggingIn: boolean
    onLogInClick: () => void
    userHasFeature: (featureName: FeatureName) => boolean
    user: User
    userID: string
    factionID: string
    setUser: Dispatch<React.SetStateAction<User>>
    userStat: UserStat
    setUserStat: Dispatch<React.SetStateAction<UserStat>>
    userRank: UserRank
    setUserRank: Dispatch<React.SetStateAction<UserRank>>
}

const initialState: AuthState = {
    isLoggingIn: false,
    onLogInClick: () => {
        return
    },
    userHasFeature: () => {
        return false
    },
    user: FallbackUser,
    userID: FallbackUser.id,
    factionID: FallbackUser.faction_id,
    setUser: () => {
        return
    },
    userRank: "NEW_RECRUIT",
    setUserRank: () => {
        return
    },
    userStat: {
        id: "",
        view_battle_count: 0,
        last_seven_days_kills: 0,
        total_ability_triggered: 0,
        ability_kill_count: 0,
        mech_kill_count: 0,
    },
    setUserStat: () => {
        return
    },
}

export const AuthContext = createContext<AuthState>(initialState)

export const AuthProvider: React.FC = ({ children }) => {
    const { fingerprint } = useFingerprint()
    const [isLoggingIn, setIsLoggingIn] = useState(true)
    const [passportPopup, setPassportPopup] = useState<Window | null>(null)
    const popupCheckInterval = useRef<NodeJS.Timer>()

    const [userFromPassport, setUserFromPassport] = useState<UserFromPassport>()
    const [user, setUser] = useState<User>(initialState.user)
    const userID = user.id
    const factionID = user.faction_id

    const [userStat, setUserStat] = useState<UserStat>(initialState.userStat)
    const [userRank, setUserRank] = useState<UserRank>(initialState.userRank)
    const { query: gameserverLoginCheck } = useQuery(GameServerLoginCheck(fingerprint), false)
    const { mutate: passportLoginCheck } = useMutation(PassportLoginCheck)

    const authCheckCallback = useCallback(
        async (event?: MessageEvent) => {
            if (event && !("issue_token" in event.data)) return
            const issueToken = event?.data.issue_token as string
            // Check passport server login
            if (!userFromPassport) {
                try {
                    const resp = await passportLoginCheck({
                        issue_token: issueToken,
                        fingerprint,
                    })
                    if (resp.error || !resp.payload) {
                        setUserFromPassport(undefined)
                        return
                    }
                    setUserFromPassport(resp.payload)
                } catch (err) {
                    console.error(err)
                }
            }
        },
        [fingerprint, passportLoginCheck, userFromPassport],
    )

    useEffect(() => {
        gameserverLoginCheck()
            .then((resp) => {
                if (resp.error || !resp.payload) {
                    setUser(initialState.user)
                    return
                }

                setUser(resp.payload)
            })
            .finally(() => setIsLoggingIn(false))
    }, [userFromPassport, gameserverLoginCheck, setIsLoggingIn])

    // Check if login in the iframe has been successful (window closed), if closed then do clean up
    useEffect(() => {
        if (!passportPopup) return

        // Listening for a token coming from the iframe
        window.addEventListener("message", authCheckCallback, false)

        const clearPopupCheckInterval = () => {
            popupCheckInterval.current && clearInterval(popupCheckInterval.current)
        }

        clearPopupCheckInterval()
        popupCheckInterval.current = setInterval(async () => {
            if (!passportPopup) return clearPopupCheckInterval()
            if (passportPopup.closed) {
                clearPopupCheckInterval()
                setPassportPopup(null)
                await authCheckCallback()
                setIsLoggingIn(false)
                window.removeEventListener("message", authCheckCallback)
            }
        }, 1000)

        return clearPopupCheckInterval
    }, [passportPopup, authCheckCallback])

    useEffect(() => {
        authCheckCallback()
    }, [authCheckCallback])

    // Open iframe to passport web to login
    const onLogInClick = useCallback(async () => {
        if (isLoggingIn) return
        setIsLoggingIn(true)
        const href = `${PASSPORT_WEB}external/login?origin=${window.location.origin}`
        const popup = window.open(href, "_blank")

        setPassportPopup(popup)
    }, [isLoggingIn])

    const userHasFeature = useCallback(
        (featureName: FeatureName) => {
            if (!userID || !user.features) return false
            const index = user.features.findIndex((el) => el.name === featureName)
            return index !== -1
        },
        [user.features, userID],
    )
    return (
        <AuthContext.Provider
            value={{
                isLoggingIn,
                onLogInClick,
                userHasFeature,
                user,
                userID,
                factionID,
                setUser,
                userStat,
                setUserStat,
                userRank,
                setUserRank,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext<AuthState>(AuthContext)
}

export const UserUpdater = () => {
    const { factionID, setUser, setUserStat, setUserRank } = useAuth()
    const { getFaction } = useSupremacy()
    const { setFactionColors } = useTheme()

    // Subscribe on the user
    useGameServerSubscriptionUser<User>(
        {
            URI: "",
            key: GameServerKeys.UserSubscribe,
        },
        (payload) => {
            if (!payload) return
            setUser(payload)
            setUserRank(payload.rank)
        },
    )

    // Subscribe user stats
    useGameServerSubscriptionUser<UserStat>(
        {
            URI: "",
            key: GameServerKeys.SubscribeUserStat,
        },
        (payload) => {
            if (!payload) return
            setUserStat(payload)
        },
    )

    // Listen on user ranking
    useGameServerSubscriptionUser<UserRank>(
        {
            URI: "",
            key: GameServerKeys.PlayerRank,
        },
        (payload) => {
            if (!payload) return
            setUserRank(payload)
        },
    )

    useEffect(() => {
        const faction = getFaction(factionID)

        if (faction) {
            setFactionColors({ primary: faction.primary_color, secondary: faction.secondary_color, background: shadeColor(faction.primary_color, -95) })
        }
    }, [factionID, getFaction, setFactionColors])

    return null
}
